.mainLoader {
    margin-top: 50vh;
}

.loader {
    border: 15px solid #f3f3f3; /* Light grey */
    border-top: 15px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
