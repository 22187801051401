@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?62219551');
  src: url('../font/fontello.eot?62219551#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?62219551') format('woff2'),
       url('../font/fontello.woff?62219551') format('woff'),
       url('../font/fontello.ttf?62219551') format('truetype'),
       url('../font/fontello.svg?62219551#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?62219551#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-doc:before { content: '\e800'; } /* '' */
.icon-eye-off:before { content: '\e801'; } /* '' */
.icon-tag:before { content: '\e802'; } /* '' */
.icon-down-arrow:before { content: '\e803'; } /* '' */
.icon-switch-off:before { content: '\e804'; } /* '' */
.icon-switch-on:before { content: '\e805'; } /* '' */
.icon-key:before { content: '\e806'; } /* '' */
.icon-eye:before { content: '\e807'; } /* '' */
.icon-android:before { content: '\e808'; } /* '' */
.icon-star-filled:before { content: '\e80b'; } /* '' */
.icon-cloud:before { content: '\e80c'; } /* '' */
.icon-mail:before { content: '\e80e'; } /* '' */
.icon-card-pro:before { content: '\e80f'; } /* '' */
.icon-loading:before { content: '\e810'; } /* '' */
.icon-female:before { content: '\e811'; } /* '' */
.icon-delete:before { content: '\e812'; } /* '' */
.icon-params:before { content: '\e813'; } /* '' */
.icon-edit:before { content: '\e814'; } /* '' */
.icon-add-user:before { content: '\e816'; } /* '' */
.icon-demand:before { content: '\e817'; } /* '' */
.icon-search:before { content: '\e819'; } /* '' */
.icon-megaphone:before { content: '\e81b'; } /* '' */
.icon-male:before { content: '\e81c'; } /* '' */
.icon-info:before { content: '\e81e'; } /* '' */
.icon-card-classic:before { content: '\e81f'; } /* '' */
.icon-check:before { content: '\e821'; } /* '' */
.icon-cancel:before { content: '\e822'; } /* '' */
.icon-delete-user:before { content: '\e823'; } /* '' */
.icon-plus:before { content: '\e824'; } /* '' */
.icon-down:before { content: '\e826'; } /* '' */
.icon-right:before { content: '\e829'; } /* '' */
.icon-cart:before { content: '\e82a'; } /* '' */
.icon-apple:before { content: '\e82b'; } /* '' */
.icon-partner:before { content: '\e82c'; } /* '' */
.icon-website:before { content: '\e82d'; } /* '' */
.icon-wifi:before { content: '\e830'; } /* '' */
.icon-arrow-up:before { content: '\e831'; } /* '' */
.icon-arrow-down:before { content: '\e832'; } /* '' */
.icon-credit-card:before { content: '\e833'; } /* '' */
.icon-chart:before { content: '\e834'; } /* '' */
.icon-up-arrow:before { content: '\e835'; } /* '' */
.icon-home:before { content: '\e836'; } /* '' */
.icon-loyalty-card:before { content: '\e838'; } /* '' */
.icon-user:before { content: '\e839'; } /* '' */
.icon-minus:before { content: '\e83a'; } /* '' */
.icon-qr-code-scanner:before { content: '\e83b'; } /* '' */
.icon-suitcase:before { content: '\e83e'; } /* '' */
.icon-right-open:before { content: '\e83f'; } /* '' */
.icon-verified-user:before { content: '\e846'; } /* '' */
.icon-star:before { content: '\e848'; } /* '' */
.icon-export:before { content: '\e850'; } /* '' */
.icon-organization:before { content: '\e859'; } /* '' */
.icon-member-card:before { content: '\e85c'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-arrow-left:before { content: '\f104'; } /* '' */
.icon-arrow-right:before { content: '\f105'; } /* '' */
.icon-directory:before { content: '\f114'; } /* '' */
.icon-mobile-menu:before { content: '\f142'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-undefined:before { content: '\f22c'; } /* '' */
.icon-question-circle-o:before { content: '\f29c'; } /* '' */
