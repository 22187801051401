.progress-bar {
    position: relative;
    height: 30px;
    max-height: 30px;
    width: 85%;
    margin: auto;
    border-radius: 50px;
}

.filler {
    background: #1DA598;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
    text-align: right;
    padding-right: 15px;
}

.progress-text{
    position: absolute;
    height: 100%;
    text-align: left;
    padding-left: 15px;
    width: 100%;
}

.objectif {
    position: absolute;
    background: #FF0000;
    height: 100%;
    border-radius: inherit;
    width: 5px;
}
